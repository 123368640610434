<template>
    <el-dialog title="Send Group Message" :visible.sync="visible" width="500px"
        :before-close="handleClose" class="manage-users-send-group-message">
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator" v-loading="$store.state.ManageUsersModule.sendGroupMessage">
            <form @submit.prevent="handleSubmit(onSubmit)" ref="form">
                <validation-provider :rules="{ required: true }" name="patient_stages"
                    v-slot="{ errors, valid, invalid, validated, validate }">
                    <el-checkbox-group v-model="form.patient_stages" class="group-checklist-group">
                        <div class="group-checklist-box" :class="{ 'is-checked': form.patient_stages.includes('Considering Surgery') }">
                            <el-checkbox :label="'Considering Surgery'">Considering Surgery</el-checkbox>
                        </div>
                        <div class="group-checklist-box" :class="{ 'is-checked': form.patient_stages.includes('Pre-Op') }">
                            <el-checkbox :label="'Pre-Op'">Pre-Op</el-checkbox>
                        </div>
                        <div class="group-checklist-box" :class="{ 'is-checked': form.patient_stages.includes('Post-Op') }">
                            <el-checkbox :label="'Post-Op'">Post-Op</el-checkbox>
                        </div>
                        <div class="group-checklist-box" :class="{ 'is-checked': form.patient_stages.includes('Non-Surgical') }">
                            <el-checkbox :label="'Non-Surgical'">Non-Surgical</el-checkbox>
                        </div>
                    </el-checkbox-group>
                    <div v-if="errors[0]" class="invalid-feedback d-block">
                        {{ errors[0] }}
                    </div>
                </validation-provider>
                <base-input placeholder="Message" name="message" :rules="{ required: true }" label="Message:">
                    <textarea v-model="form.message" class="form-control" rows="3" resize="none"></textarea>
                </base-input>
                <div class="d-flex justify-content-end">
                    <base-button type="gray" @click="handleClose">Cancel</base-button>
                    <base-button type="dark-blue" native-type="submit">Send Message</base-button>
                </div>
            </form>
        </validation-observer>
    </el-dialog>
</template>
  
<script>
import patientConstants from "@/constants/patientConstants";
import FileInput from '@/components/Inputs/FileInput.vue';
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';
export default {
    props: {
        visible: Boolean,
    },
    mixins: [generateErrorMessageMixin],
    data() {
        return {
            form: {
                patient_stages: [],
                message: '',
            }
        };
    },
    methods: {
        handleClose() {
            let validation = this.$refs.formValidator;
            this.$refs.form.reset();
            validation.reset();
            this.form.patient_stages = [];
            this.form.message = '';
            this.$emit('closeDialog');
        },
        async onSubmit() {
            let validation = this.$refs.formValidator;
            this.$emit('closeDialog');
            this.$store.commit('ManageUsersModule/toggleSendGroupMessage',true)
            let patient_stages = [];
            this.form.patient_stages.forEach((el)=>{
                patient_stages = [ ...patient_stages, ...patientConstants.patient_stages[el] ];
            });
            console.log(patient_stages);
            try {
                let response = await this.$store.dispatch('ManageUsersModule/_sendGroupMessage', { fd: {...this.form , patient_stages} });
                if(response.data){
                    let {
                    data: { data, message },
                    } = response;
                    this.$notify.success({
                        title: 'Group Message',
                        dangerouslyUseHTMLString: true,
                        message: message
                    });
                }
                this.handleClose();
            } catch (error) {
                this.showErrorOnForm(error, 'Group Message', validation);
            }
            this.$store.commit('ManageUsersModule/toggleSendGroupMessage',false)
        }
    },
};
</script>
  
<style scoped lang="scss"></style>
  